import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { API } from "../../../Services/api-service";
import Format from "./format";
import { toast } from "react-toastify";
import {
  MathfieldElement,
  MathfieldOptions,
  MathfieldElementAttributes,
  VirtualKeyboardPolicy,
  VirtualKeyboardCommands,
} from "mathlive";
import "./playground.css";

const MathsWorkSheet = forwardRef((props, ref) => {
  const [isDrawing, setIsDrawing] = useState(false);
  const [font, setFont] = useState(false);
  const [isMove, setIsMove] = useState("stop");
  const [currentDiv, setCurrentDiv] = useState("");
  const [size, setSize] = useState("");
  const [color, setColor] = useState("");
  const [elementWidth, setElementWidth] = useState("");
  const [elementHeight, setElementHeight] = useState("");
  const [keyBoardVal, setKeyboardVal] = useState("");
  const [createBox, setCreateBox] = useState(false);
  const prevSelectedBoxRef = useRef(null);
  const [scroll, setScroll] = useState(0);
  const canvasRef = useRef(null);

  const canvasOffsetX = useRef(null);
  const canvasOffsetY = useRef(null);
  const startX = useRef(null);
  const startY = useRef(null);

  const elementRef = useRef(null);
  const draggableElementRef = useRef(null);

  useEffect(() => {
    const canvasDiv = document.getElementById("canvas");
    const canvasOffset = canvasDiv.getBoundingClientRect();
    canvasOffsetY.current = canvasOffset.top;
    canvasOffsetX.current = canvasOffset.left;
  }, []);

  useEffect(() => {
    const canvasDiv = document.getElementById("canvas");
    const canvasChildren = document.querySelectorAll('[id^="div-"]');
    canvasChildren.forEach((child) => {
      child.remove();
    });
    if (props.answers) {
      for (var key in props.answers) {
        const id = key;
        const ans = props.answers[key];
        // let ansArray = ans.split(":");
        const delimiter = ":";
        const answerType = ans.slice(0, ans.indexOf(delimiter));
        let ansArray = ans.slice(ans.indexOf(delimiter) + delimiter.length);
        const coordinatesArray = id.split("-");
        const xCoordinate = coordinatesArray[1];
        const yCoordinate = coordinatesArray[2];
        const divWidth = coordinatesArray[3];
        const divHeight = coordinatesArray[4];
        if (answerType === "latex") {
          let newElem = document.createElement("math-field");
          newElem.setAttribute("drag", "false");
          newElem.addEventListener("input", (ev) => {
            setKeyboardVal(`latex:${ev.target.value}`);
            newElem.setAttribute("data-ans", `latex:${ev.target.value}`);
          });
          newElem.addEventListener("click", (ev) => {
            if (newElem.getAttribute("drag") === "false") {
              ev.target.focus();
              props.showTextEditor(true);
              draggableElementRef.current = ev.target;
              prevSelectedBoxRef.current = newElem;
              setCurrentDiv(newElem);
            } else {
              newElem.addEventListener("mousedown", dragMouseDown);
              const mouseDownEvent = new MouseEvent("mousedown", {
                bubbles: true,
                cancelable: true,
              });
              newElem.dispatchEvent(mouseDownEvent);
            }
          });
          newElem.addEventListener("focusin", (ev) => {
            if (newElem.getAttribute("drag") === "false") {
              window.mathVirtualKeyboard.show();
              props.setShowAddPageBtn(false);
            }
          });

          newElem.addEventListener("focusout", () => {
            let val = newElem.getAttribute("data-ans");
            if (val && val !== undefined && val !== null) {
              newElem.style.border = "2px solid rgb(104, 214, 0)";
            } else {
              newElem.style.border = "1px solid #333";
            }
            window.mathVirtualKeyboard.hide();
            props.setShowAddPageBtn(true);
          });
          newElem.setAttribute(
            "id",
            `div-${xCoordinate}-${yCoordinate}-${divWidth}-${divHeight}`
          );
          newElem.setAttribute("data-ans", ans);
          newElem.inlineShortcuts = {
            $: "\\$",
          };
          newElem.mathModeSpace = "\\:";
          newElem.menuItems = [];
          newElem.style.position = "absolute";
          if (ans) {
            newElem.style.border = "2px solid #68D600";
          } else {
            newElem.style.border = "1px solid black";
          }
          newElem.style.borderRadius = "12px";
          newElem.style.background = "rgba(255, 255, 255, 0.4)";
          newElem.style.left = xCoordinate + "%";
          newElem.style.top = yCoordinate + "%";
          newElem.style.width = divWidth + "%";
          newElem.style.height = divHeight + "%";
          newElem.style.fontSize = props.styles?.size;
          newElem.style.color = props.styles?.color;
          newElem.style.cursor = "pointer";
          newElem.style.overflowY = "hidden";
          newElem.style.overflowX = "hidden";
          newElem.innerHTML = ansArray;
          canvasDiv.appendChild(newElem);
        } else {
          let newElem = document.createElement("div");
          newElem.setAttribute(
            "id",
            `div-${xCoordinate}-${yCoordinate}-${divWidth}-${divHeight}`
          );
          newElem.setAttribute("contenteditable", "true");
          newElem.setAttribute("data-ans", ans);
          newElem.style.position = "absolute";
          if (ans) {
            newElem.style.border = "2px solid #68D600";
          } else {
            newElem.style.border = "1px solid black";
          }
          newElem.style.borderRadius = "12px";
          newElem.style.background = "rgba(255, 255, 255, 0.4)";
          newElem.style.left = xCoordinate + "%";
          newElem.style.top = yCoordinate + "%";
          newElem.style.width = divWidth + "%";
          newElem.style.height = divHeight + "%";
          newElem.style.fontSize = props.styles?.size;
          newElem.style.color = props.styles?.color;
          newElem.style.cursor = "pointer";
          newElem.style.overflowY =
            answerType === "descriptive" ? "auto" : "hidden";
          newElem.style.overflowX = "hidden";
          newElem.addEventListener("mousedown", dragMouseDown);
          canvasDiv.appendChild(newElem);
        }
      }
      setSize(props.styles?.size);
      setColor(props.styles?.color);
    }
  }, [props.answers]);

  useEffect(() => {
    if (currentDiv && props.keyboard && createBox) {
      let currentDivId = currentDiv.getAttribute("id");
      const canvasDiv = document.getElementById("canvas");
      let cloneId = currentDiv.getAttribute("id");
      let cloneIdArr = cloneId.split("-");
      const leftCoordinate = parseFloat(cloneIdArr[1]);
      const topCoordinate = parseFloat(cloneIdArr[2]);
      const width = cloneIdArr[3];
      const height = cloneIdArr[4];
      currentDiv.remove();
      if (props.keyboard[currentDivId]) {
        let newElem = document.createElement("math-field");
        newElem.setAttribute("drag", "false");
        newElem.addEventListener("input", (ev) => {
          setKeyboardVal(`latex:${ev.target.value}`);
          newElem.setAttribute("data-ans", `latex:${ev.target.value}`);
        });
        newElem.addEventListener("click", (ev) => {
          if (newElem.getAttribute("drag") === "false") {
            ev.target.focus();
            props.showTextEditor(true);
            draggableElementRef.current = ev.target;
            prevSelectedBoxRef.current = newElem;
            setCurrentDiv(newElem);
          } else {
            newElem.addEventListener("mousedown", dragMouseDown);
            const mouseDownEvent = new MouseEvent("mousedown", {
              bubbles: true,
              cancelable: true,
            });
            newElem.dispatchEvent(mouseDownEvent);
          }
        });
        newElem.addEventListener("focusin", () => {
          if (newElem.getAttribute("drag") === "false") {
            window.mathVirtualKeyboard.show();
            props.setShowAddPageBtn(false);
          }
        });
        newElem.addEventListener("focusout", () => {
          let val = newElem.getAttribute("data-ans");
          if (val && val !== undefined && val !== null) {
            newElem.style.border = "2px solid rgb(104, 214, 0)";
          } else {
            newElem.style.border = "1px solid #333";
          }
          window.mathVirtualKeyboard.hide();
          props.setShowAddPageBtn(true);
        });
        newElem.style.position = "absolute";
        newElem.setAttribute(
          "id",
          `div-${leftCoordinate}-${topCoordinate}-${width}-${height}`
        );
        newElem.inlineShortcuts = {
          $: "\\$",
        };
        newElem.mathModeSpace = "\\:";
        newElem.menuItems = [];
        newElem.style.border = "2px solid rgb(90, 158, 237)";
        newElem.style.borderRadius = "12px";
        newElem.style.background = "rgba(255, 255, 255, 0.4)";
        newElem.style.left = leftCoordinate + "%";
        newElem.style.top = topCoordinate + "%";
        newElem.style.width = width + "%";
        newElem.style.height = height + "%";
        if (props.styles) {
          newElem.style.fontSize = props.styles?.size;
          newElem.style.color = props.styles?.color;
        }
        newElem.style.cursor = "pointer";
        canvasDiv.appendChild(newElem);
        setCurrentDiv(newElem);
        draggableElementRef.current = newElem;
        prevSelectedBoxRef.current = newElem;
      } else {
        let newElem = document.createElement("div");
        newElem.style.position = "absolute";
        newElem.setAttribute(
          "id",
          `div-${leftCoordinate}-${topCoordinate}-${width}-${height}`
        );
        newElem.style.border = "2px solid rgb(90, 158, 237)";
        newElem.style.borderRadius = "12px";
        newElem.style.background = "rgba(255, 255, 255, 0.4)";
        newElem.style.left = leftCoordinate + "%";
        newElem.style.top = topCoordinate + "%";
        newElem.style.width = width + "%";
        newElem.style.height = height + "%";
        if (props.styles) {
          newElem.style.fontSize = props.styles?.size;
          newElem.style.color = props.styles?.color;
        }
        newElem.style.cursor = "pointer";
        canvasDiv.appendChild(newElem);
        setCurrentDiv(newElem);
        prevSelectedBoxRef.current = newElem;
        draggableElementRef.current = newElem;
      }
      setCreateBox(false);
    }
  }, [props.keyboard]);

  useEffect(() => {
    const handleScroll = (event) => {
      setScroll(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const startDrawingRectangle = ({ nativeEvent }) => {
    props.setShowAddPageBtn(false);
    let canvasDiv = document.getElementById("canvas");
    nativeEvent.preventDefault();
    nativeEvent.stopPropagation();
    setIsDrawing(true);
    if (elementRef.current !== null) {
      elementRef.current = null;
      canvasDiv.style.cursor = "default";
      setIsDrawing(false);
    } else {
      startX.current =
        ((nativeEvent.clientX - canvasOffsetX.current) * 100) /
        canvasDiv.scrollWidth;
      startY.current =
        ((nativeEvent.clientY + scroll - canvasOffsetY.current) * 100) /
        canvasDiv.scrollHeight;

      // startX.current =
      //   ((nativeEvent.clientX - canvasOffsetX.current) /
      //     canvasOffsetWidth.current) *
      //   100;
      // startY.current =
      //   ((nativeEvent.clientY - canvasOffsetY.current + scroll) /
      //     canvasOffsetHeight.current) *
      //   100;
      let newElem = document.createElement("div");
      newElem.setAttribute("contenteditable", "true");
      newElem.style.position = "absolute";
      newElem.style.border = "1px solid black";
      newElem.style.borderRadius = "12px";
      newElem.style.background = "rgba(255, 255, 255, 0.4)";
      newElem.style.color = color;
      newElem.style.fontSize = size;
      newElem.style.left = startX.current + "%";
      newElem.style.top = startY.current + "%";
      // newElem.style.display = "flex";
      // newElem.style.justifyContent = "center";
      // newElem.style.alignItems = "center";
      newElem.style.overflowX = "hidden";
      canvasDiv.appendChild(newElem);
      canvasDiv.style.cursor = "crosshair";
      elementRef.current = newElem;
    }
  };

  const drawRectangle = ({ nativeEvent }) => {
    let canvasDiv = document.getElementById("canvas");
    if (!isDrawing) {
      return;
    }
    nativeEvent.preventDefault();
    nativeEvent.stopPropagation();
    const newMouseX =
      ((nativeEvent.clientX - canvasOffsetX.current) * 100) /
      canvasDiv.scrollWidth;
    const newMouseY =
      ((nativeEvent.clientY + scroll - canvasOffsetY.current) * 100) /
      canvasDiv.scrollHeight;

    // const newMouseX =
    //   ((nativeEvent.clientX - canvasOffsetX.current) /
    //     canvasOffsetWidth.current) *
    //   100;
    // const newMouseY =
    //   ((nativeEvent.clientY - canvasOffsetY.current + scroll) /
    //     canvasOffsetHeight.current) *
    //   100;
    if (elementRef.current !== null) {
      elementRef.current.style.width =
        Math.abs(newMouseX - startX.current) + "%";
      elementRef.current.style.height =
        Math.abs(newMouseY - startY.current) + "%";
      elementRef.current.style.left =
        newMouseX - startX.current < 0 ? newMouseX + "%" : startX.current + "%";
      elementRef.current.style.top =
        newMouseY - startY.current < 0 ? newMouseY + "%" : startY.current + "%";
      setElementWidth(newMouseX - startX.current);
      setElementHeight(newMouseY - startY.current);
      elementRef.current.setAttribute(
        "id",
        `div-${startX.current}-${startY.current}-${Math.abs(
          newMouseX - startX.current
        )}-${Math.abs(newMouseY - startY.current)}`
      );
      elementRef.current.addEventListener("mousedown", dragMouseDown);
    }
  };

  const dragMouseUp = () => {
    document.onmouseup = null;
    document.onmousemove = null;
  };

  const dragMouseMove = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let canvasDiv = document.getElementById("canvas");
    const newMouseX =
      ((event.clientX - canvasOffsetX.current) * 100) / canvasDiv.scrollWidth;
    const newMouseY =
      ((event.clientY + window.scrollY - canvasOffsetY.current) * 100) /
      canvasDiv.scrollHeight;

    draggableElementRef.current.style.left = newMouseX + "%";
    draggableElementRef.current.style.top = newMouseY + "%";

    const elementId = draggableElementRef.current.id;
    const coordinatesArray = elementId.split("-");
    const divWidth = coordinatesArray[3];
    const divHeight = coordinatesArray[4];
    draggableElementRef.current.setAttribute(
      "id",
      `div-${newMouseX}-${newMouseY}-${Math.abs(divWidth)}-${Math.abs(
        divHeight
      )}`
    );
  };

  const dragMouseDown = (event) => {
    event.preventDefault();
    draggableElementRef.current = event.target;
    const contentEditable = event.target.getAttribute("contenteditable");
    if (contentEditable === "true") {
      // draggableElementRef.current.focus();
    } else {
      let canvasDiv = document.getElementById("canvas");
      startX.current =
        ((event.clientX - canvasOffsetX.current) * 100) / canvasDiv.scrollWidth;
      startY.current =
        ((event.clientY + window.scrollY - canvasOffsetY.current) * 100) /
        canvasDiv.scrollHeight;
      document.onmouseup = dragMouseUp;
      document.onmousemove = dragMouseMove;
    }
  };

  const handleFontSize = (value, color) => {
    setSize(value);
    setColor(color);
    let pDiv = document.getElementById("canvas");
    var cDiv = pDiv.children;
    for (var i = 0; i < cDiv.length; i++) {
      if (cDiv[i].tagName == "DIV" || cDiv[i].tagName == "MATH-FIELD") {
        //or use toUpperCase()
        cDiv[i].style.fontSize = value; //do styling here
        cDiv[i].style.color = color; //do styling here
      }
    }
    setFont(false);
  };

  const closeModel = () => {
    setFont(false);
  };

  const onMouseClick = (event) => {
    let ele = document.getElementById(event.target.id);
    let val;
    if (
      typeof prevSelectedBoxRef.current === "object" &&
      prevSelectedBoxRef.current !== null &&
      isMove === "stop"
    ) {
      val = prevSelectedBoxRef.current.getAttribute("data-ans");
      if (val && val !== undefined) {
        prevSelectedBoxRef.current.style.border = "2px solid rgb(104, 214, 0)";
      } else {
        prevSelectedBoxRef.current.style.border = "1px solid #333";
      }
    }
    if (isMove === "stop") {
      if (ele !== null) {
        props.setShowAddPageBtn(false);
        ele.style.border = "2px solid #5A9EED";
      }

      prevSelectedBoxRef.current = ele;
      setCurrentDiv(ele);
      props.handleBoxSelection(ele);
    }
  };

  useImperativeHandle(ref, () => ({
    handleDelete() {
      if (currentDiv) {
        currentDiv.remove();
      }
    },

    handleFormat() {
      setFont(true);
    },

    handleSave() {
      const answersJson = {};
      let can = document.getElementById("canvas");
      let child = can.childNodes;
      child.forEach((item) => {
        if (item.id.includes("div")) {
          let val = item.getAttribute("data-ans");
          if (val && val !== null) {
            let ansArray = val.split(":");
            if (ansArray[0] === "select" || ansArray[0] === "select-one") {
              answersJson[item.id] = val;
              let questionId = ansArray.slice(-1);
              item.setAttribute("question-id", questionId);
              if (ansArray[0] === "select-one") {
                item.setAttribute(
                  "user-submission",
                  `select-one:no:${questionId}`
                );
                item.setAttribute("type", "single-select");
                item.setAttribute("selected", "no");
              } else {
                item.setAttribute("type", "multi-select");
                item.setAttribute("user-submission", `select:no:${questionId}`);
                item.setAttribute("selected", "no");
              }
            } else if (ansArray[0] === "latex") {
              answersJson[item.id] = val.replace(/\s/g, "\\:");
              answersJson[item.id] = answersJson[item.id].replace(
                /\n|\r|\t/g,
                ""
              );
              item.setAttribute("type", "latex");
            } else if (ansArray[0] === "@descriptive") {
              answersJson[item.id] = val;
              item.setAttribute("type", "descriptive");
              item.style.padding = "12px";
              item.style.overflowY = "auto";
            } else {
              answersJson[item.id] = val;
              item.setAttribute("type", "open-ended");
            }
          } else {
            answersJson[item.id] = "";
          }
        }
      });

      const formData = new FormData();
      var blob = new Blob([document.getElementById("canvas").outerHTML]);
      let filedata = new File([blob], `${props.exerciseId}.html`);
      formData.append("exerciseId", props.exerciseId);
      formData.append("answers", JSON.stringify(answersJson));
      formData.append("style", JSON.stringify({ size: size, color: color }));
      formData.append("isLatex", JSON.stringify(props.keyboard));
      formData.append("file", filedata);
      if (props.ansVariations) {
        formData.append("ansVariations", JSON.stringify(props.ansVariations));
      }
      API.post(
        `/exercises/${props.exerciseId}/page/${
          props.pageId ? props.pageId : props.currentPageId
        }`,
        formData
      )
        .then((res) => {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          props.handleStatus("iframe", res.data.page?.properties?.answers);
          props.fetchExercise("preview");
          API.get(`/exercises/${props.exerciseId}/page/${props.currentPage}`)
            .then((res) => {
              if (res.data.success) {
                props.setWorkSheetData(res.data.page);
              }
            })
            .catch((err) => {
              console.log("unable to fecth data", err);
            });
        })
        .catch((e) => {
          toast.error("Error While Updating Page", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    },

    handleCopy() {
      let canvasDiv = document.getElementById("canvas");
      let cloneNode = draggableElementRef.current.cloneNode(true);
      if (cloneNode.tagName === "MATH-FIELD") {
        let val = cloneNode.getAttribute("data-ans");
        if (val && val !== undefined && val !== null) {
          // let ansArray = val.split(":");
          const delimiter = ":";
          const answerType = val.slice(0, val.indexOf(delimiter));
          let ansArray = val.slice(val.indexOf(delimiter) + delimiter.length);
          cloneNode.innerHTML = ansArray;
        }
        cloneNode.setAttribute("drag", "false");
        cloneNode.addEventListener("input", (ev) => {
          setKeyboardVal(`latex:${ev.target.value}`);
          cloneNode.setAttribute("data-ans", `latex:${ev.target.value}`);
        });
        cloneNode.addEventListener("click", (ev) => {
          if (cloneNode.getAttribute("drag") === "false") {
            ev.target.focus();
            props.showTextEditor(true);
            draggableElementRef.current = ev.target;
            prevSelectedBoxRef.current = cloneNode;
            setCurrentDiv(cloneNode);
          } else {
            cloneNode.addEventListener("mousedown", dragMouseDown);
            const mouseDownEvent = new MouseEvent("mousedown", {
              bubbles: true,
              cancelable: true,
            });
            cloneNode.dispatchEvent(mouseDownEvent);
          }
        });
        cloneNode.addEventListener("focusin", () => {
          if (cloneNode.getAttribute("drag") === "false") {
            window.mathVirtualKeyboard.show();
            props.setShowAddPageBtn(false);
          }
        });
        cloneNode.addEventListener("focusout", () => {
          if (val && val !== undefined && val !== null) {
            cloneNode.style.border = "2px solid rgb(104, 214, 0)";
          } else {
            cloneNode.style.border = "1px solid #333";
          }
          window.mathVirtualKeyboard.hide();
          props.setShowAddPageBtn(true);
        });
      }
      cloneNode.addEventListener("mousedown", dragMouseDown);
      let cloneId = cloneNode.getAttribute("id");
      let cloneIdArr = cloneId.split("-");
      const leftCoordinate = parseFloat(cloneIdArr[1]);
      const topCoordinate = parseFloat(cloneIdArr[2]);
      const width = cloneIdArr[3];
      const height = cloneIdArr[4];
      const newLeft = leftCoordinate + 1;
      const newTop = topCoordinate + 1;
      cloneNode.style.top = `${newTop}%`;
      cloneNode.style.left = `${newLeft}%`;
      cloneNode.setAttribute(
        "id",
        `div-${newLeft}-${newTop}-${width}-${height}`
      );
      cloneNode.inlineShortcuts = {
        $: "\\$",
      };
      cloneNode.mathModeSpace = "\\:";
      cloneNode.menuItems = [];
      canvasDiv.appendChild(cloneNode);
      draggableElementRef.current = cloneNode;

      let val;
      if (
        typeof prevSelectedBoxRef.current === "object" &&
        prevSelectedBoxRef.current !== null
      ) {
        val = prevSelectedBoxRef.current.getAttribute("data-ans");
        if (val && val !== undefined) {
          prevSelectedBoxRef.current.style.border =
            "2px solid rgb(104, 214, 0)";
        } else {
          prevSelectedBoxRef.current.style.border = "1px solid #333";
        }
      }
      setCurrentDiv(cloneNode);
      props.handleBoxSelection(cloneNode);
      prevSelectedBoxRef.current = cloneNode;
    },

    handleDrag() {
      let can = document.getElementById("canvas");
      let child = can.childNodes;
      child.forEach((item) => {
        if (!item.querySelector("img")) {
          item.setAttribute("contenteditable", "false");
          item.style.cursor = "move";
          item.addEventListener("mousedown", dragMouseDown);
        }
      });
      const mathFields = document.querySelectorAll("math-field");
      mathFields.forEach((item) => {
        item.setAttribute("drag", "true");
      });
      props.showTextEditor(false);
      setIsMove("move");
      props.displayCancel(true);
    },

    handleType() {
      let can = document.getElementById("canvas");
      let child = can.childNodes;
      child.forEach((item) => {
        if (!item.querySelector("img")) {
          item.setAttribute("contenteditable", "true");
          item.style.cursor = "pointer";
          item.removeEventListener("mousedown", dragMouseDown);
        }
      });

      const mathFields = document.querySelectorAll("math-field");
      mathFields.forEach((item) => {
        item.setAttribute("drag", "false");
      });
      props.displayCancel(false);
      setIsMove("stop");
      props.handleBoxSelection(currentDiv);
    },

    createBox() {
      setCreateBox(true);
    },
  }));

  return (
    <div>
      {font && (
        <Format
          open={font}
          handleFontSize={handleFontSize}
          closeModalHandler={closeModel}
          size={size}
          fontColor={color}
        />
      )}
      <div
        ref={canvasRef}
        id="canvas"
        onDoubleClick={startDrawingRectangle}
        onMouseMove={drawRectangle}
        onClick={onMouseClick}
        style={{
          position: "relative",
          border: "2px solid #B8B8B8",
        }}
      >
        <div style={{ textAlign: "center" }}>
          {props.workSheet?.map((img) => {
            return <img src={img} alt="test" width={"100%"} />;
          })}
        </div>
      </div>
    </div>
  );
});

export default MathsWorkSheet;
