import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import TableData from "./NewTableData";
import { API } from "../../Services/api-service";
import StudentList from "./StudentList";
import TableComponent from "./TableComponent";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { selectDarkMode } from "../../Redux/Reducers/darkModeSlice";

const NewExerciseTracker = () => {
  const isDarkMode = useSelector(selectDarkMode);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const studentId = params.get("studentId");
  const [activeTable, setActiveTable] = useState("table1");
  const [activeStudentList, setActiveStudentList] = useState([]);
  const [exerciseReportData, setExerciseReportData] = useState([]);
  const [summaryLoader, setSummaryLoader] = useState(false);
  const [topic, setTopic] = useState(null);
  const [days, setDays] = useState(30);
  const [exerciseSumary, setExerciseSumary] = useState(null);
  const [mentorId, setMentorId] = useState(null);
  const [topicId, setTopicId] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(studentId);
  const [status, setStatus] = useState("All");
  const [assigned, setAssigned] = useState(false);
  const [exerciseLoader, setExerciseLoader] = useState(false);
  const [currentPageSummary, setCurrentPageSummary] = useState(1);
  const [totalPagesSummary, setTotalPagesSummary] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [recentlyAssigned, setRecentlyAssigned] = useState([]);

  const handleViewClick = (row) => {
    setActiveTable("table2");
    setTopicId(row.topicId);
  };

  const handleBackClick = () => {
    setActiveTable("table1");
  };

  const getExerciseSummary = () => {
    if (selectedStudent && mentorId) {
      setSummaryLoader(true);
      API.get(
        `/mentor/exercise-report?studentId=${selectedStudent}&MID=${mentorId}&page=${currentPageSummary}&days=${days}`
      )
        .then((res) => {
          setExerciseSumary(res.data);
          if (currentPageSummary == 1) {
            setRecentlyAssigned(res.data?.recentlyAssigned);
          }
          setTotalPagesSummary(res.data.pages);
          if (res.data?.topicSummary?.length > 0) {
            setTopicId(res.data?.topicSummary[0]?.topicId);
          }
          setSummaryLoader(false);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  useEffect(() => {
    getExerciseSummary();
  }, [selectedStudent, days, mentorId, currentPageSummary]);

  const getActiveStudents = () => {
    API.get(`/mentor/students?detailed=1`)
      .then((res) => {
        setActiveStudentList(res.data.students);
        if (!studentId) {
          setSelectedStudent(res?.data?.students[0]?.studentId);
        }
        setMentorId(res?.data?.MID);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getActiveStudents();
  }, []);

  const getExerciseReport = () => {
    if (selectedStudent && topicId && activeTable == "table2") {
      setExerciseLoader(true);
      API.get(
        `/mentor/exercise-report/${topicId}?studentId=${selectedStudent}&page=${currentPage}&status=${status.toLowerCase()}`
      )
        .then((res) => {
          setExerciseReportData(res.data?.exercises);
          setTotalPages(res.data.pages);
          setTopic(res.data.topic);
          setExerciseLoader(false);
        })
        .catch((err) => {
          console.log(err, "err");
        });
    }
  };
  useEffect(() => {
    getExerciseReport();
  }, [selectedStudent, topicId, status, activeTable, currentPage]);

  const header = [
    "Concept",
    "Completed",
    "Assigned",
    "Pending",
    "View Details",
  ];
  const header1 = ["Exercise", "Concept", "Assigned on", "Status", "View"];

  return (
    <Grid
      container
      spacing={2}
      style={{
        marginTop: "2rem",
      }}
    >
      {/* Left Container */}
      <Grid item xs={12} md={12} lg={3}>
        <StudentList
          studentsList={activeStudentList}
          selectedStudent={selectedStudent}
          setSelectedStudent={setSelectedStudent}
          setActiveTable={setActiveTable}
        />
      </Grid>

      {/* Right Container */}
      <Grid
        item
        xs={12}
        md={12}
        lg={8}
        style={{
          border: isDarkMode ? "1px solid #252525" : "1px solid #EBF1FF",
          height: "max-content",
          padding: "20px",
          marginLeft: "1rem",
          borderRadius: "8px",
          boxShadow: isDarkMode && "0px 0px 2px 0px #0053F429",
          background: isDarkMode && "#1E1E1E",
        }}
      >
        {/* Call the TableData component here */}
        {activeTable === "table1" ? (
          <>
            <TableData
              heading={"Exercises Summary"}
              header={header}
              data={exerciseSumary}
              topicSummary={exerciseSumary?.topicSummary}
              onViewClick={handleViewClick}
              show={true}
              setDays={setDays}
              summaryLoader={summaryLoader}
              studentId={selectedStudent}
              currentPage={currentPageSummary}
              totalPages={totalPagesSummary}
              setCurrentPage={setCurrentPageSummary}
            />

            <TableData
              heading={"Recent Assignments"}
              header={header1}
              data={exerciseSumary}
              topicSummary={recentlyAssigned}
              show={false}
              summaryLoader={summaryLoader}
              assigned={assigned}
              setAssigned={setAssigned}
              studentId={selectedStudent}
              getExerciseSummary={getExerciseSummary}
            />
          </>
        ) : (
          <TableComponent
            apiResponse={exerciseReportData}
            onViewClick={handleBackClick}
            topic={topic}
            status={status}
            setStatus={setStatus}
            assigned={assigned}
            setAssigned={setAssigned}
            exerciseLoader={exerciseLoader}
            selectedStudent={selectedStudent}
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
            getExerciseReport={getExerciseReport}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default NewExerciseTracker;
